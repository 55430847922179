import _ from 'underscore';
import $ from 'jquery';
import Promise from 'bluebird';
import * as safeJSON from '@bingads-webui/safe-json';
import { queryify } from '../util/url';
import {
  HTTPOptions, Args, Signature, Callback, StringDictionary, ErrorDetails, ExtraInfo, HTTPMethod,
} from '../types';

const defaultOptions: HTTPOptions<any> = {
  contentType: 'application/json',
  dataType: 'json',
  cache: false,
  type: 'GET',
};

function getResponseHeaders(xhrObject: JQueryXHR): StringDictionary {
  const ret: StringDictionary = {};

  const str = xhrObject.getAllResponseHeaders();
  const arr = str && str.split('\r\n');
  const reg = /^([^:]*): (.*)$/;

  _.each(arr, (header) => {
    // sample header: 'key: value'
    const matches = header.match(reg);
    if (matches) {
      const [, key, value] = matches;
      ret[key] = value;
    }
  });

  return ret;
}

function stringify(data: any, { method, contentType }: HTTPOptions<any>) : undefined | string {
  if (!method || method === 'GET') {
    return undefined;
  }

  if (!_.isString(data) && contentType === 'application/json') {
    return JSON.stringify(data);
  }

  return data;
}

export function signature<Options extends HTTPOptions<TResult>, TResult>(...originalArgs: Args<Options, TResult> | [Args<Options, TResult>] | [IArguments]): Signature<Options, TResult> {
  let arg = <Args<Options, TResult>>originalArgs;
  if (_.isArray(originalArgs[0])) {
    [arg] = originalArgs;
  } else if (_.isArguments(originalArgs[0])) {
    arg = <Args<Options, TResult>>_.toArray(originalArgs[0]);
  }

  const [first, second, third] = arg;

  if (_.isObject(first)) {
    // options is first arg
    return [
      (<Options>first).uri || (<Options>first).url || '',
      <Options>first,
      <Callback>second,
    ];
  } if (_.isFunction(second)) {
    // url is string, but options is omited
    return [
      <string>first,
      <Options>{},
      second,
    ];
  }

  return [
    <string>first,
    <Options>second,
    third,
  ];
}

export function ajax<Options extends HTTPOptions<TResult>, TResult>(...args: Args<Options, TResult>) {
  const [url, {
    customAjax,
    method,
    xhrFields,
    success,
    error,
    data,
    customParamSerialize,
    urlParameters,
    contentType,
    dataType,
    converters,
    cache,
    beforeSend,
    complete,
    bypassAjaxLogging,
    processData,
    xhr: xhrOption,
    parse,
    i18nErrors,
    headers,
    timeout,
  }, cb] = signature(args);
  const ajaxFunc = customAjax || $.ajax;

  // todo handle upper case method
  const $op = <Options>_.defaults({
    type: method ? method.toUpperCase() : undefined,
    xhrFields: {
      withCredentials: xhrFields && xhrFields.withCredentials,
      responseType: xhrFields && xhrFields.responseType,
    },
    success(response: any, textStatus: JQuery.Ajax.SuccessTextStatus, xhrObject: JQuery.jqXHR) {
      if (success) {
        success(response, textStatus, xhrObject);
      }

      if (cb) {
        cb(null, response, textStatus, xhrObject);
      }
    },
    error(xhrObject: JQuery.jqXHR, textStatus: JQuery.Ajax.ErrorTextStatus, errorThrown: string) {
      // NOFIX:
      // If you need this logic, you should wrap options.error to add this in your code
      // if (xhr && xhr.status === 401) {
      //   state.trigger('http.auth.error');
      // }

      if (error) {
        error(xhrObject, textStatus, errorThrown);
      }

      const details: ErrorDetails = {
        xhr: xhrObject,
        textStatus,
        error: errorThrown,
        headers: getResponseHeaders(xhrObject),
      };

      safeJSON.parse(xhrObject.responseText, (err, res) => {
        if (err) {
          if (cb) {
            cb(_.extend(new Error('could not parse error'), details));
          }
          return;
        }

        details.body = res;

        if ($op.parse) {
          details.errors = $op.parse(res);
        }

        if ($op.i18nErrors && details.errors) {
          details.i18nErrors = $op.i18nErrors;
        }

        if (cb) {
          cb(details);
        }
      });
    },
  }, {
    contentType,
    dataType,
    converters,
    cache,
    beforeSend,
    complete,
    bypassAjaxLogging,
    processData,
    xhr: xhrOption,
    parse,
    i18nErrors,
    headers,
    timeout,
  }, defaultOptions);

  $op.url = queryify(url, {
    method,
    data,
    customParamSerialize,
    urlParameters,
  });
  $op.data = stringify(data, { method, contentType });

  return Promise
    .resolve(ajaxFunc($op))
    .catch((err: JQuery.jqXHR) => {
      const extraInfo: ExtraInfo = {
        body: safeJSON.parseSync(err.responseText),
        headers: getResponseHeaders(err),
      };

      if ($op.parse && extraInfo.body) {
        extraInfo.errors = $op.parse(extraInfo.body);
      }

      if ($op.i18nErrors && extraInfo.errors) {
        extraInfo.i18nErrors = $op.i18nErrors;
      }

      // TODO: this error increase noise in logging errors, and will revert this behavior for now
      // We are adding some extra logging to determine the extent of readyState=0 errors
      // if (err && err.readyState === 0) {
      //   setTimeout(() => {
      //     // eslint-disable-next-line
      //     throw new Error(`http-util: readyState=0 fail. type: ${$op && $op.type} url: ${$op && $op.url}`);
      //   }, 1);
      // }

      throw _.extend(err, extraInfo);
    });
}

export function defaults(defaultOps: HTTPOptions<any>): HTTPMethod {
  return function wrappedAjax<Options extends HTTPOptions<TResult>, TResult>(...originalArgs: Args<Options, TResult>) {
    const [url, options, cb] = signature<Options, TResult>(originalArgs);

    const op = <Options>_.defaults({}, options, defaultOps);

    return ajax<Options, TResult>(url, op, cb);
  };
}

export function xhr() {
  return (<Function>$.ajaxSettings.xhr)();
}

export const get = defaults({ method: 'GET' });
export const post = defaults({ method: 'POST' });
export const put = defaults({ method: 'PUT' });
export const patch = defaults({ method: 'PATCH' });
// delete is a reserved keyword
export const $delete = defaults({ method: 'DELETE' });
